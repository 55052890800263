import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Home from "../components/home";
import ArticlePreview from "../components/article-preview";
import SEO from "../components/seo";

const RootIndex = ({ data, location }) => {
  const posts = data?.allContentfulBlogPost?.edges;
  return (
    <Layout location={location}>
      <div style={{ background: "#fff" }}>
        <SEO />
        <Home />
        <div className="wrapper">
          <h2 className="section-headline text-lg mb-1">
            Latest posts / 最近の文章
          </h2>
          <ul className="m-auto">
            {posts.slice(0, 3).map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 800
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
