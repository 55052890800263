import React from "react";
import avatar from "../../static/avatar.jpg";
import "../styles/global.css";

const Home = () => (
  <section className="items-center m-auto flex flex-col md:flex-row wrapper">
    <img
      src={avatar}
      className="rounded-full w-32 mr-6 mb-5 md:mb-0"
      alt="Profile picture"
    />
    <div className="mb-4">
      <h2 className="mb-2 text-xl">Yuka Masuda</h2>
      <p className="max-w-xl mb-0">
        Feminist, foodie, front-end engineer in Paris.
        ごはんのことばかり考えているフロントエンドエンジニア in パリ。 English{" "}
        <a
          href="https://twitter.com/ykmsd"
          target="_blank"
          rel="noopener noreferrer"
        >
          @ykmsd
        </a>
        , 日本語{" "}
        <a
          href="https://twitter.com/yukamasuda"
          target="_blank"
          rel="noopener noreferrer"
        >
          @yukamasuda
        </a>
        , 飼い犬{" "}
        <a
          href="https://twitter.com/shibainuinparis"
          target="_blank"
          rel="noopener noreferrer"
        >
          @shibainuinparis
        </a>
      </p>
    </div>
  </section>
);

export default Home;
